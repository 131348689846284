import React from 'react';
import Layout from "../components/layouts";
import styled, { createGlobalStyle } from 'styled-components'
import header from "../images/header-sustainable-gift.jpg";
import polar from "../images/sustainable-gift-polar-bear.png";
import ornaments from "../images/sustainable-gift-ornaments.jpg";
const HolidayTips = () => {

  const HolidayTips = createGlobalStyle`
    body {
      background: #02255C;
      color: #fff;
      font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
    }
  `;

  const StyledContainer = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 34px;
  `;

  const StyledHeaderImage = styled.img`
    width: 100%;
    margin-top: 75px;
  `;

  const StyledFlex = styled.div`
    display: flex;
    flex-flow: row wrap;
    margin-top: 60px;
    font-size: 14px;

     @media(min-width: 768px) {
      font-size: 16px;
    }
  `;

  const LeftContent = styled.div`
    width: 100%;

    @media(min-width: 768px) {
      width: 70%;
    }
  `;

  const RightContent = styled.div`
    display: none;

    @media(min-width: 768px) {
      width: 30%;
      display: flex;
      flex-flow: column;
      align-items: center;

      h3 {
        font-size: 24px;
        text-align: center;
        padding: 0 24px;
      }
    }
  `;

  const StyledPolarBears = styled.img`
    width: 90%;
    margin-bottom: 24px;
    margin-top: -24px;
  `;

  const StyledOrnaments = styled.img`
    width: 100%;
  `;

  const StyledButton = styled.button`
    padding: 16px;
    border-radius: 20px;
    color: #fff;
    border: none;
    background: #fe1974;
    font-weight: bold;
    font-size: 24px;
    margin: 24px 0;
  `;

  const StyledCards = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 60px;
  `;

  const Card = styled.img`
    width: 100%;

    @media(min-width: 768px) {
      width: 32%;
      background: red;
      height: 500px;
    }
  `; 

  const RightContentMobile = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 60px 0;

    h3 {
      font-size: 24px;
      text-align: center;
      padding: 0 24px;
    }
    @media(min-width: 768px) {
      display: none;
    }
  `
  return (
    <Layout navBg>
      <HolidayTips />
      <StyledContainer>
        <StyledHeaderImage src={header} />
        <StyledFlex>
          <LeftContent>
            <p>
            Every gift box could use a card, and some gifts are the card themselves! For those looking for just the right card to express themselves, we've got you covered.  
            </p>
            <p>
            Our <strong>2024 “Polar Bear Approved” Card Collection</strong> celebrates some important themes close to our holiday hearts, and they’ve been illustrated by three creative forces close to our mission.
            </p>
            <p>
            <strong>DOWNLOAD THE PRINTABLE + SHAREABLE CARDS BELOW</strong>
            </p>
            <p>
            Artist, Grace Park card reminds us of the joy and connection that comes with buying from local shops, and supporting members of your community.
            <br />See more of Grace's work <strong>@gracehyein.park</strong>
            </p>
            <p>
            Artist Lanusca's card shines a light on the joy of discovery that comes with shopping for pre-loved finds at thrift stores. A second-hand item in the hands of your loved ones is twice as nice.
            <br />See more of Lanusca's work <strong>@lanusca</strong>
            </p>
            <p>
            Artist Luke Mcgarry is reminding us to invest our support in the most vulnerable members of our community - the animals who depend on the kindness and commitment of local welfare organizations.
            <br />See more of Luke's work <strong>@lukemcgarry</strong>
            </p>
          </LeftContent>
          <RightContent>
            <StyledPolarBears src={polar} />
            <h3>Support Global Inheritance for the holidays!</h3>
            <StyledButton>
              DONATE NOW
            </StyledButton>
            <StyledOrnaments src={ornaments} />
          </RightContent>
        </StyledFlex>
        <StyledCards>
          <Card src={header} />
          <Card src={header} />
          <Card src={header} />
        </StyledCards>
        <RightContentMobile>
        <StyledPolarBears src={polar} />
            <h3>Support Global Inheritance for the holidays!</h3>
            <StyledButton>
              DONATE NOW
            </StyledButton>
            <StyledOrnaments src={ornaments} />
        </RightContentMobile>
      </StyledContainer>
    </Layout>
);
}

export default HolidayTips;


